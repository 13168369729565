import { GuildBenefitListProps, GuildBenefitList as GuildBenefitListv1 } from './GuildBenefitList'
import { GuildBenefitListPropsv2 } from './GuildBenefitListv2'
import { GuildBenefitListPropsv3 } from './GuildBenefitListv3'
import { GuildBenefitList as GuildBenefitListv3 } from './GuildBenefitListv3'

export const GuildBenefitList: React.FC<GuildBenefitListProps | GuildBenefitListPropsv2> = (props) => (
  <GuildBenefitListv1 {...props} />
)

export const GuildBenefitListNew: React.FC<GuildBenefitListPropsv3> = (props) => {
  return <GuildBenefitListv3 {...props} />
}
