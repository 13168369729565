import React, { useState } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { Path, SubmitHandler, useForm, UseFormRegister } from 'react-hook-form'
import { Button } from '@/atoms/Button'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { CaptionSM, CaptionXS, LabelLG, TitleLG } from '@/atoms/Text'
import { paths } from '@/constants'
import { useLegacyEffect } from '@/molecules/utils'
import { useLegalDocumentsByClient } from '@/services/EllisIslandService/legalDocumentHooks'
import { buildLoginUrl } from '@/utils/BuildLoginUrl'
import { useSafeTrack } from '@/utils/analytics'
import { cLogger } from '@/utils/logging/client-logger'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface IFormValues {
  email: string
  password: string
  legalDocuments: { [key: string]: boolean } | null
}
type InputProps = {
  name: Path<IFormValues>
  label: string
  register: UseFormRegister<IFormValues>
  required?: { value: boolean; message: string } | boolean
  pattern?: { value: RegExp; message: string }
  placeholder?: string
  type?: string
  hideLabel?: boolean
  error?: string
}

export type AuthStep = 'email' | 'create-account'

type SignUpAuthFormProps = {
  step: AuthStep
  userEmail: string
  offerId?: string
} & ModalStateTypes

type ModalStateTypes =
  | {
      isModal?: true
      onEmailSubmit: () => void
    }
  | {
      isModal?: false
      onEmailSubmit?: never
    }

const stepNumber = {
  email: 0,
  'create-account': 2,
}

const Input: React.FC<InputProps> = ({ label, name, register, required, pattern, error, ...rest }) => (
  <div className="flex flex-col">
    <label className="photon-label-sm pb-1.5 font-whitney font-medium">{label}</label>
    <input
      {...register(name, { required, pattern })}
      {...rest}
      aria-invalid={error ? 'true' : 'false'}
      className={classNames(
        'photon-paragraph-md border border-b rounded-lg p-2 w-full !duration-[0ms] focus-visible:ring-transparent focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-offset-transparent disabled:bg-white',
        error ? 'border-error-500' : 'border-core-gray-300',
      )}
    />
    {error && (
      <CaptionXS role="alert">
        <span className="text-error-500">{error || 'An error occurred'}</span>
      </CaptionXS>
    )}
  </div>
)

// TODO: on create-account step, need to add path for edge case where user with account some how makes it to that step and enters the wrong password
export const SignUpAuthForm: React.FC<SignUpAuthFormProps> = ({ step, userEmail, isModal, offerId, onEmailSubmit }) => {
  const [error, setError] = useState('')
  const { t } = useTranslate('guild')
  const { asPath } = useRouter()
  const { requiredLegalDocs, optionalLegalDocs } = useLegalDocumentsByClient()

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<IFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { email: userEmail, password: '', legalDocuments: null },
  })
  const queryString = asPath.includes('?') ? `${asPath.split('?')[1]?.split('#')[0]}` : ''
  const queryStringWithDelimiter = (symbol: '?' | '&') => (queryString === '' ? '' : `${symbol}${queryString}`)
  const track = useSafeTrack()

  const router = useRouter()

  useLegacyEffect(() => {
    if (isModal) {
      if (step === 'email') track('Guild Sign Up Flow Started')
      if (step === 'create-account') track('Guild Sign Up Flow Create Password Started')
    }
  }, [track, step, isModal])

  const isCreateAccountStep = step === 'create-account'
  const handleSignup = async ({
    email,
    password,
    legalDocuments,
  }: {
    email: string
    password: string
    legalDocuments: { [key: string]: boolean } | null
  }) => {
    track('Guild Sign Up Flow Attempt Account Creation')
    const userCheck = await fetch(`/api/authByApi/checkUser?email=${encodeURIComponent(email)}`)
    const userCheckJson = await userCheck.json()
    const optionalDocsArray = legalDocuments ? Object.keys(legalDocuments).filter((key) => !!legalDocuments[key]) : []
    const requiredDocsArray = requiredLegalDocs.map((doc) => doc.id)

    if (userCheckJson.exists) {
      const ellisOauthUrl = buildLoginUrl(paths.guild.pricing) + `&email=${encodeURIComponent(email)}`
      router.push(ellisOauthUrl)
      return
    }

    await axios
      .post('/api/authByApi/signUp', {
        email: email.trim(),
        password,
        legalDocuments: [...requiredDocsArray, ...optionalDocsArray],
      })
      .then((res) => {
        if (res.data.status === 'Success') {
          track('Guild Signup Account Created')
          const urlString = paths.guild.signup.plan
          router.push(`${urlString}${queryStringWithDelimiter('?')}`)
        }
      })
      .catch((e) => {
        cLogger().error('Error creating account', e)
        setError(e?.response?.data?.message || 'An error occurred, please try again or contact support')
      })
  }

  const onSubmit: SubmitHandler<IFormValues> = async ({ email, password, legalDocuments }) => {
    if (isCreateAccountStep) {
      handleSignup({ email, password, legalDocuments })
    } else {
      const userCheck = await axios.get(`/api/authByApi/checkUser?email=${encodeURIComponent(email)}`)
      if (userCheck?.data?.exists === true) {
        track('Guild Sign Up Flow User Exists')
        const urlString = offerId ? `/guild/checkout/${offerId}` : '/guild/pricing'
        const url =
          buildLoginUrl(`${urlString}${queryStringWithDelimiter('?')}`) + `&email=${encodeURIComponent(email)}`
        return router.push(url)
      }
      track('Guild Sign Up Flow Email Submitted')
      if (!isModal) {
        const urlString = offerId ? `/guild/signup/create-account/${offerId}` : '/guild/signup/create-account'
        router.push(`${urlString}?email=${encodeURIComponent(email)}${queryStringWithDelimiter('&')}`)
      } else {
        onEmailSubmit()
      }
    }
  }

  const stepNumValue = stepNumber[step]
  const totalValue = 4
  const stepTitle = {
    email: t('enterYourEmailAddressToSetUpAccount', 'Enter your email address to set up your account'),
    'create-account': t('createAPassswordToStartMembership', 'Create a password to start your membership'),
  }

  const buttonText = {
    email: t('continue', 'Continue'),
    'create-account': t('next', 'Next'),
  }

  return (
    <PaddedContainer className="min-h-[546px] !p-8 sm:w-[440px]">
      <FlexColumn>
        <CaptionSM className="mb-4 font-thin tracking-widest">
          <Translate t={t} i18nKey="stepNofN" values={{ stepNumValue: stepNumValue, totalValue: 4 }}>
            STEP {{ stepNumValue }} OF {{ totalValue }}
          </Translate>
        </CaptionSM>
        <div className="pb-2">
          <TitleLG>{stepTitle[step]}</TitleLG>
        </div>
        <CaptionSM className="pb-7 leading-5">
          {t(
            'soCloseFewClicksAway',
            `You're so close! Just a few clicks away from exploring our exciting library of films and shows!`,
          )}
        </CaptionSM>
        <form name="account-form" onSubmit={handleSubmit(onSubmit)}>
          <FlexColumn className="gap-2">
            <Input
              register={register}
              name="email"
              label={t('emailAdddress', 'Email address')}
              required={true}
              error={errors.email?.message || errors.email ? 'Please enter a valid email address' : undefined}
            />

            {isCreateAccountStep && (
              <>
                <Input
                  register={register}
                  name="password"
                  label={t('password', 'Password')}
                  required={true}
                  pattern={{
                    value: /^.{8,}$/i,
                    message: t('passwordMustBeAtLeastEightCharacters', 'Password must be at least 8 characters'),
                  }}
                  type="password"
                  error={errors.password?.message || errors.password ? 'Please enter a valid password' : undefined}
                />

                <FlexRow className="gap-2 px-1 pt-7">
                  {requiredLegalDocs?.length > 0 && (
                    <>
                      <CaptionXS className="text-core-gray-500">
                        By proceeding, you acknowledge that you have read and agree to our{' '}
                        {requiredLegalDocs.map((doc, index) => (
                          <span key={doc.id}>
                            <InternalLink key={doc.id} href={doc.source} className="text-link-blue" target="_blank">
                              {doc.title}
                            </InternalLink>
                            {handleLegalDocsPunctuation(requiredLegalDocs.length, index)}
                          </span>
                        ))}
                      </CaptionXS>
                    </>
                  )}
                </FlexRow>
                {optionalLegalDocs?.length > 0 &&
                  optionalLegalDocs.map((doc) => (
                    <FlexRow key={doc.id} className="gap-2 px-1 pb-7">
                      <input
                        {...register(`legalDocuments.${doc.id}`)}
                        type="checkbox"
                        required={true}
                        aria-required={true}
                        aria-invalid={errors.legalDocuments ? 'true' : 'false'}
                        defaultChecked={false}
                      />
                      <CaptionXS className="text-core-gray-500">
                        I have read and agree to the{' '}
                        <InternalLink key={doc.id} href={doc.source} className="text-link-blue" target="_blank">
                          {doc.title}
                        </InternalLink>
                      </CaptionXS>
                    </FlexRow>
                  ))}
              </>
            )}
            <Button
              variant="core-gray-900"
              type="submit"
              className="min-h-[50px] w-full px-6 py-[12px]"
              disabled={!!errors.password || !!errors.email || !!errors.legalDocuments}
            >
              {isSubmitting && <LoadingSpinnerIcon size={18} color="white" />}
              <LabelLG>{!isSubmitting && buttonText[step]}</LabelLG>
            </Button>
            {error && (
              <CaptionXS role="alert">
                <span className="text-error-500">{error}</span>
              </CaptionXS>
            )}
          </FlexColumn>
        </form>
      </FlexColumn>
    </PaddedContainer>
  )
}

const handleLegalDocsPunctuation = (length: number, index: number) => {
  if (length === 0) return ''
  if (length === 1) return '.'
  return index === length - 1 ? '.' : index === length - 2 ? ' and ' : ', '
}
