import React from 'react'
import classNames from 'classnames'
import { CircleCheckIcon } from '@/atoms/Icons/CircleCheckIcon'
import { EmptyCircleIcon } from '@/atoms/Icons/EmptyCircleIcon'
import { IconColor } from '@/atoms/utils'
import { GuildPlanPerk } from '@/molecules/GuildPlanPerk'
import { useTranslate } from '@/utils/translate/translate-client'

export type GuildBenefitListProps = {
  className?: string
  tier: 'basic' | 'monthly-premium' | 'yearly-premium'
  slug?: string
  checkColor?: IconColor
}

export const GuildBenefitList: React.FC<GuildBenefitListProps> = ({
  className,
  tier,
  slug,
  checkColor = 'core-oxide',
}) => {
  const { t } = useTranslate('guild-benefits')

  const benefits = [
    {
      description: t('guildHelpsFundAngelOriginals', 'Help fund Angel Originals, speeding up new content releases.'),
      tiers: ['basic', 'monthly-premium', 'yearly-premium'],
    },
    {
      description: t('voteOnShows', 'Vote on pilots, concepts, and feature films to decide what we make next.'),
      tiers: ['basic', 'monthly-premium', 'yearly-premium'],
    },
    {
      description: t('joinLivestreams', 'Join exclusive livestreams including LIVE recordings of Dry Bar Comedy!'),
      tiers: ['basic', 'monthly-premium', 'yearly-premium'],
    },
    {
      description: t(
        'earlyAccessToStreamAngelOriginals',
        'Early Access to stream our shows after their theatrical release.',
      ),
      tiers: ['basic', 'monthly-premium', 'yearly-premium'],
    },
    {
      description: t(
        'twoTicketsToEveryReleasev2',
        'Two movie tickets to every Angel Studios theatrical release to use or give away.',
      ),
      tiers: ['monthly-premium', 'yearly-premium'],
    },
    {
      description: t('discountOnMerch', 'Get 20% off merch in the Angel Online Store.'),
      tiers: ['monthly-premium', 'yearly-premium'],
    },
  ]

  return (
    <ul className={classNames('flex flex-col gap-4', className)}>
      {benefits.map((benefit) => {
        const hasBenefit = benefit.tiers.indexOf(tier) > -1
        const pricingPage = slug && slug === 'pricing'
        return (
          <li key={benefit.description}>
            <GuildPlanPerk
              captionClassName={classNames(
                '!max-w-none ml-4',
                { '!text-core-gray-400': !hasBenefit },
                { 'photon-caption-sm': pricingPage },
              )}
              icon={
                hasBenefit ? (
                  <CircleCheckIcon size={pricingPage ? 16 : 24} color1={checkColor} className="flex-none" />
                ) : (
                  <EmptyCircleIcon size={pricingPage ? 16 : 24} color1="core-gray-400" className="flex-none" />
                )
              }
              description={benefit.description}
            />
          </li>
        )
      })}
    </ul>
  )
}
