import { NextRouter } from 'next/router'

type HandleSignUpFlowExperimentType = (
  e: React.MouseEvent<HTMLAnchorElement>,
  url: string,
  router: NextRouter,
  signUpFlowFeatureValue: string,
  showModalFunc: () => void,
) => void

export const handleSignUpFlowExperiment: HandleSignUpFlowExperimentType = (
  e,
  url,
  router,
  signUpFlowFeatureValue,
  showModalFunc,
) => {
  if (signUpFlowFeatureValue === 'modal') {
    e.preventDefault()
    showModalFunc()
    return
  }
  if (signUpFlowFeatureValue === 'nav') {
    e.preventDefault()
  }
  router.push(url)
}
