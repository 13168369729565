import React from 'react'
import classNames from 'classnames'
import { CaptionMD } from '@/atoms/Text'

interface GuildPlanPerkProps {
  className?: string
  icon: React.ReactNode
  description: string | React.ReactNode
  captionClassName?: string
}

export const GuildPlanPerk: React.FC<GuildPlanPerkProps> = ({ className, icon, description, captionClassName }) => {
  return (
    <div className={classNames('flex flex-row', className)}>
      {icon}
      <CaptionMD className={classNames('max-w-[230px] text-left text-core-gray-950', captionClassName)}>
        {description}
      </CaptionMD>
    </div>
  )
}
